// Theme works on gatsby-plugin-theme-ui(theme-ui) and lekoarts/gatsby-theme-minimal-blog theme.
// This file cusotomize over it. ref: https://github.com/LekoArts/gatsby-starter-minimal-blog#changing-your-fonts
import { merge } from "theme-ui";
import originalTheme from "@lekoarts/gatsby-theme-minimal-blog/src/gatsby-plugin-theme-ui/index";

const theme = merge(originalTheme, {
  // ref: https://theme-ui.com/color-modes/#responding-to-the-prefers-color-scheme-media-query
  useColorSchemeMediaQuery: 'system',
  fonts: {
    body: `BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif!important; */`,
  },
  styles: {
    h1: {
      variant: `text.heading`,
      fontSize: [5, 5, 5, 5],
      mt: 3,
    },
    // post title
    h2: {
      fontSize: [4, 5, 5, 5],
      mt: 4,
    },
    h3: {
      mt: 5,
    },
    h4: {
      fontSize: [2, 3, 3, 3],
    },
    p: {
      fontSize: [1, 1, '1.1rem'],
      lineHeight: `1.7`,
    },
    ul: {
      li: {
        fontSize: [1, 1, '1.1rem'],
      },
    },
    ol: {
      li: {
        fontSize: [1, 1, '1.1rem'],
      },
    },
  }
});

export default theme;
